import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { fuseConfig } from '../../../../../fuse-config';

@Injectable()
export class DocumentTypesService implements Resolve<any>
{
    products: any[];
    onProductsChanged: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private http: HttpClient
    )
    {
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getProducts('')
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getProducts(destinatary): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/domain/LoadDocumentTypes?destinatary=' + destinatary)
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    getDocumentTypeForExceptions(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/domain/GetDocumentTypeForSupplierExceptions')
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    getDocumentTypeForExceptionsForOrder(orderId): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/domain/GetDocumentTypeForSupplierExceptionsForOrder?orderId=' + orderId)
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    getRequiredDocumentTypeForForOrder(orderId): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/domain/LoadRequiredDocumentTypeForOrder?orderId=' + orderId)
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    getDocumentTypeForOrderRequirements(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/domain/GetDocumentTypeForOrderRequirements')
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    deleteDocumentType(dt): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.post(fuseConfig.apiUrl + 'api/domain/DeleteDocumentType', dt)
                .subscribe((response: any) => {

                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }
}
