import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { fuseConfig } from '../../../../../fuse-config';

@Injectable()
export class UsersService implements Resolve<any>
{
    products: any[];
    onProductsChanged: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private http: HttpClient
    )
    {
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUsers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUsers(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/users/GetUsers')
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    getOrderReferrers(orderId): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(fuseConfig.apiUrl + 'api/users/GetOrderReferrers?orderId=' + orderId)
                .subscribe((response: any) => {
                    this.products = response;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });
    }

    deleteUser(user): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.post(fuseConfig.apiUrl + 'api/users/DeleteUser', user)
                .subscribe((response: any) => {

                    // this.products = response;
                    // this.onProductsChanged.next(this.products);
                     resolve(response);
                }, reject);
        });
    }
}
